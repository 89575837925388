@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Evita el desbordamiento horizontal */
  }
  body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400; /* O cualquier peso que prefieras */
  }
  
  
  
  /* Fondo de la aplicación */
  .bg-white {
    position: relative;
  }
  
  /* Figuras geométricas */
  .bg-blue-500 {
    background-color: #3b82f6;
  }
  
  
  
  .rounded-full {
    border-radius: 9999px;
  }
  
  .opacity-20 {
    opacity: 0.2;
  }
  
  /* Triángulo */
  .border-l-transparent {
    border-left-color: transparent;
  }
  
  .border-r-transparent {
    border-right-color: transparent;
  }